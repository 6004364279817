import { ClockCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Radio, Space, Timeline } from "antd";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
export default NumbersPage;

const numbers = [
  {
    number: "0",
    symbol: "๐",
    text: "ศูนย์",

    transliteration: "Soon",
  },
  {
    number: "1",
    symbol: "๑",
    text: "หนึ่ง",
    transliteration: "Neu:ng",
  },
  {
    number: "2",
    symbol: "๒",
    text: "สอง",
    transliteration: "Sorng",
  },
  {
    number: "3",
    symbol: "๓",
    text: "สาม",
    transliteration: "Saam",
  },
  {
    number: "4",
    symbol: "๔",
    text: "สี่",
    transliteration: "See",
  },
  {
    number: "5",
    symbol: "๕",
    text: "ห้า",
  },
  {
    number: "6",
    symbol: "๖",
    text: "หก",
    tranliteraion: "Hok",
  },
  {
    number: "7",
    symbol: "๗",
    text: "เจ็ด",
    transliteration: "Chet",
  },
  {
    number: "8",
    symbol: "๘",
    text: "แปด",
    transliteration: "Bpaed",
  },
  {
    number: "9",
    symbol: "๙",
    text: "เก้า",
  },
  {
    number: "10",
    symbol: "๑๐",
    text: "สิบ",
    transliteration: "Si:b",
  },
];

type NumbersPageProps = {};
function NumbersPage({}: NumbersPageProps) {
  const lessonItems: CheckLessonItem[] = useMemo(() => {
    return [
      ...numbers.map((number) => ({
        question: number.symbol,
        answer: number.symbol,
        key: number.symbol,
        options: numbers.map((number) => ({
          value: number.symbol,
          label: number.number,
        })),
      })),
      ...numbers.map((number) => ({
        question: number.text,
        answer: number.symbol,
        key: number.symbol,
        randomize: true,
        options: numbers.map((number) => ({
          value: number.symbol,
          label: number.symbol,
        })),
      })),
      ...numbers.map((number) => ({
        question: number.number,
        answer: number.symbol,
        key: number.symbol,
        randomize: true,
        options: numbers.map((number) => ({
          value: number.symbol,
          label: number.symbol,
        })),
      })),
      ...numbers.map((number) => ({
        question: number.number,
        answer: number.symbol,
        key: number.symbol,
        randomize: true,
        options: numbers.map((number) => ({
          value: number.symbol,
          label: number.text,
        })),
      })),
      ...numbers.map((number) => ({
        question: number.symbol,
        answer: number.symbol,
        key: number.symbol,
        options: numbers.map((number) => ({
          value: number.symbol,
          label: number.number,
        })),
      })),
      ...numbers.map((number) => ({
        question: number.text,
        answer: number.symbol,
        key: number.symbol,
        options: numbers.map((number) => ({
          value: number.symbol,
          label: number.number,
        })),
      })),
    ];
  }, [numbers]);

  const { current, check, next, checkedItems } = useCardCheckLesson({
    items: lessonItems,
  });

  const handleAnswer = (value: string) => {
    check(value);
  };

  const handleContinue = () => {
    next();
  };

  return (
    <>
      <LessonProgress
        items={checkedItems.map((item) => ({
          error: item.state,
          key: item.key,
        }))}
      />
      <CardCheck
        randomize={current.randomize}
        question={current.question}
        answer={current.answer}
        options={current.options}
        onAnswered={handleAnswer}
        onContinue={handleContinue}
      />
    </>
  );
}
type LessonProgressProps = {
  items: {
    error: boolean;
    key: string;
  }[];
};
function LessonProgress({ items }: LessonProgressProps) {
  return (
    <Timeline
      style={{
        transform: "rotate(-90deg)",
        width: 20,
        height: 40,
        marginTop: -20,
        /* top: -20px; */
        position: "relative",
      }}
    >
      {items.map(({ error, key }) => (
        <Timeline.Item
          key={key}
          color={error ? "red" : undefined}
        ></Timeline.Item>
      ))}
    </Timeline>
  );
}

type CheckLessonItem = {
  question: string;
  answer: string;
  key: string;
  options: { value: string; label: string }[];
  randomize?: boolean;
};
type UseCardCheckLessonProps = {
  items: CheckLessonItem[];
};
function useCardCheckLesson({ items }: UseCardCheckLessonProps) {
  const [errorState, setErrorState] = useState(false);
  const shuffledItems = useMemo(() => {
    return items.sort(() => Math.random() - 0.5);
  }, [items]);
  const [current, setCurrent] = useState(shuffledItems[0]);
  // const currentItem = useRef(shuffledItems[0]);
  type Checked = {
    state: boolean;
    key: string;
  };
  const checkedItems = useRef<Checked[]>([]);
  const check = (value: string) => {
    let state = true;
    if (value === current.answer) {
      state = false;
    }
    setErrorState(state);
    checkedItems.current.push({
      state,
      key: current.key,
    });
  };

  const next = () => {
    const currentIndex = shuffledItems.indexOf(current);
    if (currentIndex < shuffledItems.length - 1) {
      setCurrent(shuffledItems[currentIndex + 1]);
    }
  };
  return {
    current,
    check,
    next,
    errorState,
    checkedItems: checkedItems.current,
  };
}

type CardCheckProps = {
  question: ReactNode;
  answer: string;
  randomize?: boolean;
  options: { label: ReactNode; value: string }[];
  onAnswered?: (value: string) => void;
  onContinue?: () => void;
};
function CardCheck({
  question,
  answer,
  randomize = false,
  options,
  onAnswered,
  onContinue,
}: CardCheckProps) {
  const [answered, setAnswered] = useState();
  const handleFormFinish = ({ option }: any) => {
    console.log("handleFormFinish", option);

    setAnswered(option);
    onAnswered?.(option);
  };
  useEffect(() => {
    setAnswered(undefined);
  }, [question]);

  const handleContinue = () => {
    setAnswered(undefined);
    onContinue?.();
  };
  const error = answered && answered !== answer;
  console.log("error", error, answer, answered);

  const shuffledOptions = useMemo(() => {
    if (randomize) {
      return options.sort(() => Math.random() - 0.5);
    }
    return options;
  }, [options]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form layout="horizontal" onFinish={handleFormFinish}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            style={{ fontSize: "4em", minHeight: "2em", textAlign: "center" }}
            message={question}
          />
          <Form.Item name="option">
            <Radio.Group buttonStyle="solid" size="large">
              <Space wrap={true}>
                {shuffledOptions.map((option) => (
                  <Radio.Button
                    key={option.value}
                    value={option.value}
                    style={{
                      height: 60,
                      lineHeight: "3.5em",
                      ...(error && option.value === answer
                        ? {
                            borderColor: "red",
                          }
                        : {}),
                    }}
                  >
                    <div style={{ fontSize: "2em" }}>{option.label}</div>
                  </Radio.Button>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          {!answered && (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ fontSize: "2em", height: 60, width: "100%" }}
            >
              Submit
            </Button>
          )}
        </Space>
      </Form>
      {answered ? (
        <Button
          size="large"
          htmlType="button"
          onClick={() => {
            handleContinue();
            return false;
          }}
          style={{ fontSize: "2em", height: 60, width: "100%" }}
        >
          Continue
        </Button>
      ) : undefined}
    </Space>
  );
}
