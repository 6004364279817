import { Outlet, Route, RouteProps } from "react-router-dom";
import AppLayout from "../components/AppLayout";

export default function AppRoute() {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}
