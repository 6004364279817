import { Spin } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import useLocalStorageValue from "../../common/hooks/useLocalStorageValue";
import { KRATOS_PROXY_URL } from "../../constants/constants";
import useApiQuery from "../../service/dataprovider/useApiQuery";
import useAxios from "../../service/dataprovider/useAxios";
import AppUser from "../../service/model/AppUser";
import { setUser } from "../../store/global";
export type ProtectedRouteProps = {};

export default function ProtectedRoute() {
  //   const navigate = useNavigate();
  const dispatch = useDispatch();

  const [token] = useLocalStorageValue("token");
  //   const user = useSelector(({ user }: GlobalAppState) => user);
  const axios = useAxios();
  const { isLoading, isError, data, error } = useQuery(
    "user",
    async ({}) => {
      // // request with bearer token
      const result = await axios.get(`${KRATOS_PROXY_URL}/whoami`);

      // console.log("data resukt", result.data);
      const user = AppUser.createFromGoogleKratos(result.data.identity);
      dispatch(setUser(user));
      return user;
    },
    {
      // avoid retring on http 401
      retry: (count: number, error: any) => {
        // console.log('error', error);
        
        // check if response status id 401
        if (error.response?.status === 401) {
          return false;
        }
        // console.log('retry ', count, error);

        return count < 3;
      },
    }
  );
  //   const mutation = useMutation(async (token) => {
  //     try {
  //       await axios.get("http://localhost:8080/user", {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     //   navigate("/login");
  //     }
  //   });

  //   useEffect(() => {
  //     if (!user && token) {
  //       //   mutation.mutate(token);
  //     }
  //   }, [user, token]);

  const auth = !!token && !isError && !!data;

  return isLoading ? (
    <Spin spinning={isLoading}> loading </Spin>
  ) : auth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}
