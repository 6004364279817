import AppUser from "../service/model/AppUser";

export type GlobalAppState = {
  user?: AppUser;
};
export type AppAction = {
  type: string;
  data: any;
};

const SET_USER = "SET_USER";
export const initialState: GlobalAppState = {};

export function reducer(
  state: GlobalAppState = initialState,
  action: AppAction
): GlobalAppState {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.data,
      };
    default:
      return state;
  }
}

export function setUser(user: any): AppAction {
  return {
    type: SET_USER,
    data: user,
  };
}
