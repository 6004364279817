import { useState, useEffect } from "react";

export default function useSessionStorageValue(
  key: string,
  defaultValue?: any
) {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } finally {
        return storedValue;
      }
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value !== undefined) {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.removeItem(key);
    }
  }, [value]);

  return [value, setValue];
}
