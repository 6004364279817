import { Button, Form, Input, Select, Space, Spin, Table, TableColumnProps } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useLocalStorageValue from "../../common/hooks/useLocalStorageValue";
import { API_URL } from "../../constants/constants";
import useAxios from "../../service/dataprovider/useAxios";


export type WordsPageProps = {};
export default function WordsPage() {
  const [form] = useForm();
    const axios = useAxios();

    const {
      data: wordsData,
      isLoading: wordsLoading,
      refetch: wordsRefresh,
    } = useQuery<any>(`words`, async () =>
      axios.get(`${API_URL}/words/`)
    );


    const {
      data: groupsData,
      isLoading: groupsLoading,
      refetch: groupsRefresh,
    } = useQuery<any>(`groups`, async () =>
      axios.get(`${API_URL}/groups/`)
    );


    const groupsOptions = useMemo(() => {
      return (
        groupsData?.data?.result?.map((group: any) => {
          return {
            label: group.group,
            value: group.group,
          };
        }) || []
      );
    }, [groupsData]);

    const [token] = useLocalStorageValue("token");

    const handleWordSubmit = async (values: any) => {
      console.log("values", values);
      const { word, groups } = values;
      await axios.post(`${API_URL}/words/`, { words: [word], groups });
      await wordsRefresh();
      await groupsRefresh();
      form.resetFields();
    }

    const columns: TableColumnProps<any>[] = [
      {
        title: "Word",
        dataIndex: "word",
        key: "word",
      },
      {
        title: "Groups",
        dataIndex: "groups",
        key: "groups",
        render: (groups: any) => {
          return groups?.join(", ");
        },
      },
    ];

    return (
      <Spin spinning={wordsLoading || groupsLoading}>
        <Space style={{ width: "100%" }} direction="vertical">
          <Form form={form} layout="horizontal" onFinish={handleWordSubmit}>
            <Form.Item label="Word" name="word">
              <Input />
            </Form.Item>
            <Form.Item name="groups">
              <Select mode="tags" options={groupsOptions} />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form>
          <Table
            rowKey={(record: any) => record.id}
            columns={columns}
            dataSource={wordsData?.data?.result}
          />
        </Space>
      </Spin>
    );
}