import React from "react";
import logo from "./logo.svg";
import "./App.css";
import AppLayout from "./components/AppLayout";
import "antd/dist/antd.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createStoreHook, Provider } from "react-redux";
import AppRoute from "./common/AppRoute";
import ThaiWordConstructorPage from "./components/ThaiWord/ThaiWordConstructorPage";
import LoginPage from "./components/Auth/LoginPage";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import { GlobalAppState, initialState, reducer } from "./store/global";
import { configureStore } from "@reduxjs/toolkit";
import { QueryClient, QueryClientProvider } from "react-query";
import HomePage from "./components/HomePage";
import AuthCallbackPage from "./components/Auth/AuthCallbackPage";
import WordsPage from "./components/Words/WordsPage";
import { KRATOS_PROXY_URL, KRATOS_URL } from "./constants/constants";
import NumbersPage from "./components/Pages/NumbersPage";

// createStore<GlobalAppState, AppAction, any, any>(reducer, initialState);
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: initialState,
});

// console.log('env', process.env, KRATOS_URL, KRATOS_PROXY_URL);

const queryClient = new QueryClient();

function App() {
  // create store

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/constructor" element={<AppRoute />}>
              <Route path="" element={<ProtectedRoute />}>
                <Route path="" element={<ThaiWordConstructorPage />} />
              </Route>
            </Route>
            <Route path="/words" element={<AppRoute />}>
              <Route path="" element={<ProtectedRoute />}>
                <Route path="" element={<WordsPage />} />
              </Route>
            </Route>
            <Route path="/" element={<AppRoute />}>
              <Route path="" element={<ProtectedRoute />}>
                <Route path="" element={<HomePage />} />
              </Route>
            </Route>
            <Route path="/login" element={<AppRoute />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route path="/auth-callback" element={<AppRoute />}>
              <Route path="/auth-callback" element={<AuthCallbackPage />} />
            </Route>

            <Route path="/numbers" element={<AppRoute />}>
              <Route path="/numbers" element={<NumbersPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
