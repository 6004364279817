import axios, { AxiosRequestConfig } from "axios";
import { useMemo } from "react";
import useLocalStorageValue from "../../common/hooks/useLocalStorageValue";

export default function useAxios() {
    const [token] = useLocalStorageValue("token");
    const axios = useMemo(() => getAxios({ token }), [token]);
    return axios;
}

type AxiosRequestConfigWrapper = AxiosRequestConfig & {
    token: string;
}
function getAxios({token, ...defaut}: AxiosRequestConfigWrapper) {
    const getConfig = (config?: AxiosRequestConfig) => {
        const localCondig = {...defaut, ...config};
        return {
          ...localCondig,
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            ...localCondig.headers,
          },
          data: {
            ...localCondig.data,    
          }
        };
    }
    return {
      ...axios,
      get: (url: string, config?: AxiosRequestConfig) => {
        return axios.get(url, getConfig(config));
      },
      post: (url: string, data?: any, config?: AxiosRequestConfig) => {
        return axios.post(url, data, getConfig(config));
      },
    };
}