import { useSelector } from "react-redux";
import { GlobalAppState } from "../store/global";

export default function HomePage() {
  const user = useSelector(({ user }: GlobalAppState) => user);
  return (
    <div>
      <h1>Home Page</h1>
      <div>
        User: {user?.name}({user?.email})
      </div>
    </div>
  );
}
