import { Alert, Button, Space } from "antd";
import { useMemo, useState } from "react";
import AppLayout from "../AppLayout";

type ThaiWordConstructorPageProps = {};
export default function ThaiWordConstructorPage({}: ThaiWordConstructorPageProps) {
  const words = [
    {
      text: "เพลิง",
      english: "fire",
      transcription: "phleerngM",
    },
    {
      text: "แล้ว",
      english: "to complete",
      transcription: "phleerngM",
    },
    {
      text: "ชื่อ",
      english: "name",
      transcription: "cheuuF",
    },
    {
      text: "อะไร",
      english: "what",
      transcription: "aL raiM",
    },
    {
      text: "ไม่",
      english: "no; not",
      transcription: "maiF",
    },
    {
      text: "ตาม",
      english: "to follow; along; around",
      transcription: "dtaamM",
    },
    {
      text: "แล้วก็",
      english: "and then",
      transcription: "laaeoH gaawF",
    },
    {
      text: "ก็ได้",
      english: "okay",
      transcription: "gaawF daiF",
    },
    {
      text: "ทำงาน",
      english: "to work",
      transcription: "thamM ngaanM",
    },
    {
      text: "ทำงานบ้าน",
      english: "to do housework",
      transcription: "thamM ngaanM baanF",
    },
    {
      text: "บ้าน",
      english: "house",
      transcription: "baanF",
    },
    {
      text: "เปลี่ยน",
      english: "to change",
      transcription: "bpliianL",
    },
    {
      text: "ประชุม",
      english: "have a meeting",
      transcription: "bpraL choomM",
    },
    {
      text: "กินข้าว",
      english: "have a meal",
      transcription: "ginM khaaoF",
    },
    {
      text: "น้ำกิน",
      english: "drinking water",
      transcription: "naamH ginM",
    },
    {
      text: "ถวายพระพร",
      english: "to honor the king",
      transcription: "thaL waaiR phraH phaawnM",
    },
  ];

  const [wordIndex, setWordIndex] = useState(0);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [error, setError] = useState<string>();

  const letters = useMemo(() => {
    const letters = words[wordIndex].text.split("");
    // shuffle array
    for (let i = letters.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [letters[i], letters[j]] = [letters[j], letters[i]];
    }
    return letters;
  }, [wordIndex]);

  const selectedWord = selectedIndexes.map((i) => letters[i]).join("");

  const handleContinueClick = () => {
    setError(undefined);
    setWordIndex(wordIndex + 1);
    setSelectedIndexes([]);
  };
  const handleConfirmClick = () => {
    if (selectedWord !== words[wordIndex].text) {
      setError(`Corrent word is: ${words[wordIndex].text}`);
    } else {
      handleContinueClick();
    }
  };

  const handleSelectLetter = (index: number) => {
    setSelectedIndexes([...selectedIndexes, index]);
  };

  const handleDeleteLetter = () => {
    // delete last selected letter
    setSelectedIndexes(selectedIndexes.slice(0, -1));
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div style={{ fontSize: "3em" }}>{words[wordIndex].transcription}</div>
      <div style={{ fontSize: "1.4em" }}>{words[wordIndex].english}</div>
      <Alert
        style={{ fontSize: "3em", minHeight: "2em" }}
        message={selectedWord}
      />
      <Space direction="vertical" size={40}>
        <Space direction="vertical">
          <Space>
            {letters.map((letter, index) => (
              <Button
                disabled={selectedIndexes.includes(index)}
                style={{
                  fontSize: "2em",
                  minHeight: "2em",
                  minWidth: "2em",
                }}
                onClick={() => handleSelectLetter(index)}
              >
                {letter}
              </Button>
            ))}
          </Space>
          {error && <Alert type="error" message={error} />}
        </Space>
        {error ? (
          <Button onClick={handleContinueClick} type="primary">
            Continue
          </Button>
        ) : (
          <Space>
            <Button onClick={handleDeleteLetter} type="primary" danger>
              Delete
            </Button>
            <Button onClick={handleConfirmClick} type="primary">
              Confirm
            </Button>
          </Space>
        )} 
      </Space>
    </Space>
  );
}
