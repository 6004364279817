import { useState, useEffect } from "react";

export default function useLocalStorageValue(key: string, defaultValue?: any) {
  const [value, setValue] = useState(() => {
    // Get the current value from local storage, or the default value if not set
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } finally {
        return storedValue;
      }
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    console.log("set local storage value", value);
    if (value !== undefined) {
      localStorage.setItem(key, value);
    }
  }, [value]);

  return [value, setValue];
}
