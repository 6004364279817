type AppUserProps = {
  id: string;
  email: string;
  name: string;
};

type AppUserDexProps = {
  iss: string;
  sub: string;
  aud: string;
  exp: number;
  iat: number;
  at_hash: string;
  email: string;
  email_verified: boolean;
  name: string;
  federated_claims: {
    connector_id: string;
    user_id: string;
  };
};

export default class AppUser {
  public readonly id: string;
  public readonly email: string;
  public readonly name: string;
  // contructor
  constructor(props: AppUserProps) {
    this.id = props.id;
    this.email = props.email;
    this.name = props.name;
  }

  static createFromDex(dex: Partial<AppUserDexProps>): AppUser {
    return new AppUser({
      id: dex.sub || "",
      email: dex.email || "",
      name: dex.name || "",
    });
  }

  static createFromGoogleKratos(data: any): AppUser {
    return new AppUser({
      id: data.id || "",
      email: data.traits.email || "",
      name: `${data.traits.name?.first} ${data.traits.name?.last}`,
    });
  }
}
