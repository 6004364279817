import { Alert, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useLocalStorageValue from "../../common/hooks/useLocalStorageValue";
import useSessionStorageValue from "../../common/hooks/useSessionStorageValue";

export default function AuthCallbackPage() {
  //   const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [, setToken] = useLocalStorageValue("token");
  const [stateKey] = useSessionStorageValue("state");
  const [searchParams] = useSearchParams();
  const { isLoading, isError, data, error, refetch } = useQuery(
    "token",
    async () => {
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      const result = await axios.get(
        `http://127.0.0.1:5555/token?code=${code}&state=${state}&state_key=${stateKey}`
      );
      console.log("token request result", result);

      return result;
    },
    { retry: 2}
  );

  useEffect(() => {
    console.log("data", data, data?.data, data?.data?.accessToken);

    if (data?.data?.accessToken) {
      setToken(() => data?.data?.accessToken);
      //   navigate("/constructor");
      setSuccess(true);
    }
  }, [data]);
  console.log("response data", data);

  return isError ? (
    <Alert type="error" message={"Authentication error"} />
  ) : success ? (
    <Navigate to="/" />
  ) : (
    <Spin spinning={isLoading}>
      <h1>Authentication...</h1>
    </Spin>
  );
}
