import { GoogleOutlined } from "@ant-design/icons";
import { Capacitor } from "@capacitor/core";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Button } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useLocalStorageValue from "../../common/hooks/useLocalStorageValue";
import { KRATOS_URL } from "../../constants/constants";



async function startAuthItemFlow(item: string): Promise<any> {
  const result = await axios.get(`${KRATOS_URL}/self-service/${item}/api`);
  console.log(`start ${item} flow`, result);
  return result;
}
type ItemFlowProps = {
  flowId: string;
  data: any;
};
async function submitAuthItemWithFlow({
  item,
  flowId,
  data,
}: ItemFlowProps & { item: string }): Promise<any> {
  // http://127.0.0.1:4433/self-service/login?flow=73dc429b-3835-4d00-89ce-57c1c8d4d215
  const result = await axios.post(
    `${KRATOS_URL}/self-service/${item}?flow=${flowId}`,
    data
  );
  console.log(`${item} result flow`, result);
  return result;
}

// async function startLoginFlow(): Promise<any> {
//   return startAuthItemFlow("login");
// }

// async function loginWithFlow(props: ItemFlowProps): Promise<any> {
//   return submitAuthItemWithFlow({ ...props, item: "login" });
// }

// async function startRegistrationFlow(): Promise<any> {
//   return startAuthItemFlow("registration");
// }

// async function registerWithFlow(props: ItemFlowProps): Promise<any> {
//   return submitAuthItemWithFlow({ ...props, item: "registration" });
// }

type UseApiFlowProps = {
  item: string;
  redirectUrl?: string;
  autoExecute?: boolean;
};
function useAuthApiFlow({
  item,
  redirectUrl,
  autoExecute = true,
}: UseApiFlowProps) {
  const navigate = useNavigate();
  const [navigateTo, setNavigateTo] = useState<string>();

  const [, setToken] = useLocalStorageValue("token");
  const [flowId, setFlowId] = useState<string>();

  const {
    isError: isStartError,
    data: startData,
    error: startError,
    refetch: startRefresh,
  } = useQuery(
    `start-${item}-flow`,
    async () => await startAuthItemFlow(item),
    { enabled: false }
  );

  useEffect(() => {
    (async () => {
      await startRefresh();
    })();
  }, []);

  useEffect(() => {
    if (startData) {
      setFlowId(startData.data.id);
    }
  }, [startData]);

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigateTo]);

  const action = async (data: any) => {
    console.log("action", flowId, data);

    if (flowId) {
      //   loginMutation.mutate({ flowId, data });
      const result = await submitAuthItemWithFlow({ item, flowId, data });
      const { session_token } = result.data;
      console.log(`${item} result`, result, session_token);

      if (session_token) {
        setToken(() => session_token);
        setNavigateTo(redirectUrl || "/");
      }
    }
  };

  //   const loginMutation = useMutation({
  //     mutationFn: loginWithFlow,
  //   });
  return {
    startFlow: startRefresh,
    action,
  };
}

type UseApiLoginProps = {
  redirectUrl?: string;
};
function useApiLogin({ redirectUrl }: UseApiLoginProps = {}) {
  const { action, startFlow } = useAuthApiFlow({
    item: "login",
    redirectUrl,
  });
  const { register } = useRegistrationApi({
    redirectUrl,
  });
  const login = async (data: any) => {
    try {
      await action(data);
    } catch (e: any) {
      const messages: any[] = e?.response?.data?.ui?.messages;
      console.log("error", e, messages);

      if (
        messages?.some(({ text }) => {
          return text === "User with the provided identifier not found.";
        })
      ) {
        // await startRegistration();
        await register(data);
      }
    }
  };

  //   useEffect(() => {
  //     (async () => {
  //       await startRegistration();
  //     })();
  //   }, []);

  return {
    login,
  };
}

type UseApiRegistrationProps = {
  redirectUrl?: string;
};
function useRegistrationApi({ redirectUrl }: UseApiRegistrationProps) {
  const { action } = useAuthApiFlow({
    item: "registration",
    redirectUrl,
  });
  const register = async (data: any) => {
    await action(data);
  };

  return {
    register,
  };
}

// login page react component
export default function LoginPage() {
  const { login } = useApiLogin();
  const [googleUser, setGoogleUser] = useState<any>({});

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      // use hook after platform dom eady
      GoogleAuth.initialize({
        clientId:
          "836801154978-3doa2747oeb5jhsrlbl1ieppchq63nsr.apps.googleusercontent.com",
        scopes: ["profile", "email"],
        grantOfflineAccess: true,
      });
    }
  }, []);
  useEffect(() => {
    console.log("googleUser", googleUser);

    if (googleUser) {
      login({
        method: "oidc",
        provider: "google_web",
        id_token: googleUser?.authentication?.idToken,
      });
    }
  }, [googleUser]);
  const handleGoogleSignIn = async () => {
    try {
      //   await startFlow();
      const user = await GoogleAuth.signIn();
      console.log("user", user);
      setGoogleUser(user);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <h1>Login Page</h1>
      <Button
        type="primary"
        size="large"
        onClick={handleGoogleSignIn}
        icon={<GoogleOutlined />}
      >
        Login with Google
      </Button>
      {/* <Space direction="vertical">
        <h1>user:</h1>
        {googleUser ? <p>{JSON.stringify(googleUser)}</p> : undefined}
        <div>{googleUser?.accessToken}</div>
        <div>{googleUser?.idToken}</div>
      </Space> */}
    </div>
  );
}
