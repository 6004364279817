import "./AppLayout.less";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import { Alert, Button, Drawer, Layout, Menu, MenuProps, Space } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
type MenuItem = Required<MenuProps>["items"][number];
type AppLayoutProps = {
  children: React.ReactNode;
};
export default function AppLayout({ children }: AppLayoutProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleMenuClick = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    {
      key: "home",
      label: <Link to="/">Home</Link>,
      icon: <HomeOutlined />,
    },
    {
      key: "constructor",
      label: <Link to="/constructor">Constructor</Link>,
    },
    {
      key: "words",
      label: <Link to="/words">Words</Link>,
    },
    {
      key: "numbers",
      label: <Link to="/numbers">Numbers</Link>,
    },
    
    // getItem("Navigation One", "sub1", <MailOutlined />, [
    //   getItem("Option 1", "1"),
    //   getItem("Option 2", "2"),
    //   getItem("Option 3", "3"),
    //   getItem("Option 4", "4"),
    // ]),
  ];

  return (
    <Layout style={{height: '100%'}}>
      <Drawer
        title="Basic Drawer"
        placement="left"
        onClose={handleDrawerClose}
        open={drawerOpen}
      >
        <Menu style={{ width: "100%" }} mode="inline" items={items} />
      </Drawer>
      <Header className="AppLayout_Header">
        <Space style={{ width: "100%", fontSize: "1.8em" }}>
          <span onClick={handleMenuClick}>
            {drawerOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </span>
        </Space>
      </Header>
      <Content style={{display: 'flex', flexDirection: 'column'}}>
        <Layout
          style={{
            maxHeight: "1000px",
            backgroundColor: "white",
            margin: "auto",
          }}
        >
          <Content>
            <div style={{ width: "100%", padding: 20 }}>{children}</div>
          </Content>
        </Layout>
        <Footer/>
      </Content>
    </Layout>
  );
}
